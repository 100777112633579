import { NotificacaoDocumentoComponent } from './../app/pages/componetes-comum/notificacao-documento/notificacao-documento.component';
import { MatDialog } from '@angular/material/dialog';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpHeaderResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenService } from './token.service';
import { Subject } from 'rxjs';
import { UsuarioService } from './usuario.service';
import { environment } from '../environments/environment';
import { Ambiente } from '../app/ambiente/ambiente';
import { LoginComponent } from '../app/pages/login/login.component';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    API

    static usuario
    static usuarioDadosCompletos
    static usuarioLogado = false

    static menu

    Ambiente = environment.ambiente

    emitirUsuario = new EventEmitter<any>()
    emiteMenssagem = new EventEmitter<any>()

    constructor(private http: HttpClient,
        private router: Router,
        private tokenService: TokenService,
        private usuarioService: UsuarioService,
        private cookies: CookieService,
        private dialog: MatDialog) {

        environment.emitAPI.subscribe(api => {
            this.API = api
        })

    }

    // FUNÇÃO PARA PEGAR OS DADOS COMPLETOS DO USUÁRIO QUE ESTÁ LOGADO
    async pegarDadosCompletos() {

        let token

        await this.tokenService.getTokenPromise()
            .then(response => {
                token = response
            })


        await this.usuarioService.fazPesquisaUsuarioPromise(token, "", "Usuario", "Nome")
            .then(response => {
                response.forEach(
                    linha => {
                        if (linha.Login == LoginService.usuario.Login) {
                            LoginService.usuarioDadosCompletos = linha
                        }
                    }
                )
            })

        return LoginService.usuarioDadosCompletos
    }

    // MÉTODO PARA FAZER O LOGIN E VALIDAR O USUÁRIO E O ACESSO A PÁGINAS DA APLICAÇÃO
    fazerLogin(usuario) {

        usuario['Ambiente'] = environment.ambiente

        return this.http.post<any>(`${this.API}account/login`, usuario, { observe: 'response' }).toPromise()

            .then(() => {
                LoginService.usuario = usuario
                this.tokenService.setLoginToken(LoginService.usuario)
                this.tokenService.getToken().subscribe(token => {
                    if (token.token) {
                        this.emitirUsuario.emit(LoginService.usuario)
                        this.router.navigate(['/pages/principal'],{state:{origem: 'login'}})
                    }
                })

            })

            .catch(error => {
               this.emiteMenssagem.emit(error)
            })
    }

    // MÉTODO PARA FAZER O LOGOUT DO USUÁRIO, E ENVIA UM OBJETO VAZIO PARA SER O VALOR DO USUÁRIO
    fazerLogout() {
        LoginService.usuario = {}
        LoginService.usuarioDadosCompletos = {}
        this.setUsuarioLogado();
        this.tokenService.setLoginToken({})
        this.cookies.deleteAll()
    }

    // MÉTODO QUE RETORNA O VALOR DO USUÁRIO
    getUsuario() {
        return LoginService.usuario
    }

    setUsuario(usuario) {
        LoginService.usuario = usuario
        this.tokenService.setLoginToken(usuario)
    }

    getUsuarioDadosCompletos() {
        return LoginService.usuarioDadosCompletos
    }

    // VALOR BOOLEANO, PARA SABER SE HÁ UM USUÁRIO LOGADO
    setUsuarioLogado() {
        LoginService.usuarioLogado = !LoginService.usuarioLogado
    }

    // VALOR BOOLEANO, PARA SABER SE HÁ UM USUÁRIO LOGADO
    getUsuarioLogado() {
        return LoginService.usuarioLogado
    }

    // FUNÇÃO PARA PEGAR NA API TODAS AS ROTINAS NO QUAL O USUÁRIO TEM DIREITO
    async getPermissoesUsuario(): Promise<any> {

        let id = LoginService.usuarioDadosCompletos.Id
        let token

        await this.tokenService.getTokenPromise()
            .then(resposta => {
                token = resposta.token
            })


        const httpHeader = new HttpHeaders({
            'Authorization': `Bearer ${token}`
        })

        return this.http.get<any>(`${this.API}MontaMenuSistema/${id}`, { headers: httpHeader }).toPromise()
    }

    // SETA O VALOR DO MENU DA PÁGINA NA VARIÁVEL DE CLASSE
    setPermissaoComponente(menu) {
        LoginService.menu = menu
    }

    // FUNÇÃO PARA PEGAR A PERMISSÃO DO USUÁRIO EM UM MÓDULO
    getPermissaoComponente(modulo, componente) {

        let itens = LoginService.menu
        let permissao
        console.log(itens)
        itens.forEach(item => {
            if (item.title == modulo) {
                item.children.forEach(filho => {
                    if (filho.title == componente) {
                        permissao = filho.Permissao
                    }
                })
            }
        })


        return permissao
    }

    // ENVIAR PARA A API OS DADOS PAR A ALTERAÇÃO DE SENHA
    async putSenha(obj): Promise<any> {

        let token

        await this.tokenService.getTokenPromise()
            .then(
                resposta => {
                    token = resposta.token
                }
            )

        const httpHeader = new HttpHeaders({
            'Authorization': `Bearer ${token}`
        })

        return this.http.put(`${this.API}Usuario`, obj, { headers: httpHeader }).toPromise()
    }





}
